// app/error/page.jsx
'use client';
import Link from "next/link";

export default function ErrorPage() {
    return (
        <div className="flex items-center justify-center min-h-[80vh] bg-gray-100">
            <div className="text-center">
                <h1 className="text-5xl font-bold text-6ixshooter-red mb-4">Oops!</h1>
                <p className="text-xl text-gray-800 mb-6">
                    Something went wrong. Please try again later.
                </p>
                <p className="text-lg text-gray-600 mb-4">
                    If the problem persists, please contact support at:
                </p>
                <p className="text-lg text-6ixshooter-blue mb-6">
                    <Link href="mailto:support@6ixshooter.net">support@6ixshooter.net</Link>
                </p>
                <Link
                    href="/"
                    className="bg-6ixshooter-blue text-white font-semibold p-3 rounded-md shadow-md hover:bg-6ixshooter-blue-hover transition duration-300"
                >
                    Go Back Home
                </Link>
            </div>
        </div>
    );
}